import React from "react"
import Header from "../components/header"
import HeaderP from "../components/header-password"
import {Container, Row, Col} from "react-bootstrap"
import { isLoggedIn } from "../utils/auth"

const Layout = ({ children }) => {
  return (
    <Container>
      
      {isLoggedIn() ? (      
        <HeaderP></HeaderP>
      ):(
        <Header></Header>
      )}

      <main>{children}</main>

      <footer>
        <Row xs={1}>
        <Col>
            <p>© {new Date().getFullYear()} Kaan ATA  All rights reserved.</p>
            <p> Built with {` `} <a href="https://www.gatsbyjs.com" target="_blank" rel="noreferrer">Gatsby</a>
            {` `}
            And <a href="https://wordpress.org/" target="_blank" rel="noreferrer" >WordPress</a></p>
          </Col>
          <Col  xs={{ order: 'first' }}></Col>
        </Row>
      </footer>
    </Container>
  )
}

export default Layout
